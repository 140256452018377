import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { participantsStore } from '../../../../store/participantsStore';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { ReactComponent as CloseIco } from "../../../../assets/icons/close.svg";

import './styles.scss';
import { translationStore } from '../../../../store/translationStore';

const BlockUserModal = observer(({onBlock})=> {
    const ref = useRef()
    const {blockUserModalToggle} = participantsStore;
    const {translationData} = translationStore;

    useOnClickOutside(ref, ()=>blockUserModalToggle());

    return (
        <div ref={ref} className='block_user_modal'>
            <div className='header_section'>
                <div></div>
                <p className='mofal_name'>{translationData?.["block_user"]}</p>
                <CloseIco className='close_popup' onClick={()=>blockUserModalToggle()}/>
            </div>
            <p className='modal_description'>{translationData?.["block_user_description"]}</p>
            <div className='buttons_section'>
                <div><button className='action_btn' onClick={()=>blockUserModalToggle()}>{translationData?.["cancel"]}</button></div>
                <div><button className='action_btn block' onClick={onBlock}>{translationData?.["block"]}</button></div>
            </div>
        </div>
    )
})

export default BlockUserModal