import React from 'react';
import {translationStore} from "../../store/translationStore";
import {observer} from "mobx-react-lite";
import {loadingStore} from "../../store/loadingStore";

const Loading = observer(() => {
    const {translationData} = translationStore;
    const {translateLoading, unityLoading, avatarLoading} = loadingStore;

    return (
        <div className={`loading-block ${!(translateLoading || unityLoading || avatarLoading) ? "backLayer" : ""}`}>
            <div className="char-block"></div>
            <span className="loading-label">{translationData?.["please_wait"] || "Please Wait"}...</span>
        </div>
    );
})

export default Loading;
