import React, {useEffect, useRef} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {observer} from "mobx-react-lite";
// import {authStore} from "../../store/authStore";
// import {useNavigate} from "react-router-dom";
import {translationStore} from "../../store/translationStore";
import {cacheService} from "../../services/cacheService";
// import {unityStore} from "../../store/unityStore";

const DoubleLogin = observer(() => {
    // const { setDoubleLogin } = authStore;
    const {translationData} = translationStore;
    // const {setLoadUnity} = unityStore;
    // const navigate = useNavigate();
    const ref = useRef();

    const onUserNavigateForLogin = () => {
        const language = cacheService.get("Language");
        cacheService.clear();
        cacheService.set("Language", language);
        // setDoubleLogin(false);
        // setLoadUnity(false);
        window.location.assign("/")
    };

    const handleClose = () => {
        onUserNavigateForLogin();
    }

    useOnClickOutside(ref, () => handleClose(false));

    useEffect(() => {
        setTimeout(handleClose, 5000)
    }, [])

    return (
        <div className="double-login-modal">
            <div className="double-login-popup" ref={ref}>
                <span className="icon-close" onClick={handleClose}></span>
                <p className="double-login-description">
                    {translationData?.["you_can_use"]}
                </p>
                <span className="ok" onClick={handleClose}>{translationData?.["ok"]}</span>
            </div>
        </div>
    );
});

export default DoubleLogin;
