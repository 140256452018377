import { useEffect } from 'react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorStore } from '../store/errorStore';
import { unityStore } from '../store/unityStore';
import {cacheService} from "../services/cacheService";

const useErrorHandler = () => {
  const {errors, getErrorOptions} = errorStore;
  const {setIsActive} = unityStore
  const navigate = useNavigate()
  const {pathname} = useLocation();
  const condidate = useMemo(()=> Object.keys(errors).map(item => errors[item] ? item : false)[0], [errors])
  const options = useMemo(()=> {
    if(condidate === "invalid_credentials" || condidate === "permission_denied" && pathname.includes("dashboard/")) {
      const language = cacheService.get("Language");
      cacheService.clear();
      cacheService.set("Language", language);
      return {redirectPath: pathname}
    }
    const errorOptions = getErrorOptions(condidate);
    return errorOptions || {redirectPath: false}
  }, [errors])

  useEffect(() => {
    if(options?.redirectPath){
      setIsActive(false);
      if(options.redirectPath === "/maintenance") {
        navigate(options.redirectPath, {state: {from: options.from}});
      } else if(pathname.includes('guest')){
        navigate(pathname);
      } else if(options.redirectPath === "/"){
        window.location.assign(options.redirectPath);
      } else {
        navigate(options.redirectPath);
      }
    }
  }, [options])
};

export default useErrorHandler;