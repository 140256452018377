import {lazy} from "react";
import {Hoc} from "../hoc/Auth";

const EmailWr = lazy(() => import("../components/Authorization/Wrappers/EmailWr"));
const VerificationWr = lazy(() => import("../components/Authorization/Wrappers/VerificationWr"));
const ConfirmPasswordWr = lazy(() => import("../components/Authorization/Wrappers/ConfirmPasswordWr"));
const CreatePasswordWr = lazy(() => import("../components/Authorization/Wrappers/CreatePasswordWr"));
const ForgotPasswordEmailWr = lazy(() => import("../components/Authorization/Wrappers/ForgotPasswordEmailWr"));
const ForgotPasswordWr = lazy(() => import("../components/Authorization/Wrappers/ForgotPasswordWr"));
const NickNameWr = lazy(() => import("../components/Authorization/Wrappers/NickNameWr"));
const PasswordWr = lazy(() => import("../components/Authorization/Wrappers/PasswordWr"));
const TermsAndPrivacyWr = lazy(() => import("../components/Authorization/Wrappers/TermsAndPrivacyWr"));
const Dashboard = lazy(() => import("../containers/dashboard"));
const ViewWithIllustrationWr = lazy(() => import("../containers/Illustration"));
const Profile = lazy(() => import("../containers/profile"));
const Spaces = lazy(() => import("../containers/spaces"));
// const Support = lazy(() => import("../containers/help"));
const Expired = lazy(() => import("../containers/expired"));
const NotFound = lazy(() => import("../containers/notFound"));
const IllustrationWr = lazy(() => import("../containers/Illustration"));
const FastexComp = lazy(() => import("../components/fastexComp"));
// const History = lazy(() => import("../containers/history"));
const Guest = lazy(() => import("../containers/guest"));
const Maintenance = lazy(() => import("../containers/maintenance"));
const ForgotPassword = lazy(() => import("../containers/newForgot"));
const RedirectContent = lazy(() => import("../containers/redirectContent"));
const Room = lazy(() => import("../containers/room"));
const DreamPackage = lazy(() => import("../containers/dreamPackage"));
const InviteContent = lazy(() => import("../containers/inviteContent"));

const routes = [
    {
        path: "/",
        component: IllustrationWr,
        title: "Login",
    },
    {
        path: "/login",
        component: EmailWr,
        title: "EmailWr",
    },
    {
        path: "/login/pass",
        component: PasswordWr,
        title: "PasswordWr",
    },
    {
        path: "/signup/pass",
        component: CreatePasswordWr,
        title: "CreatePasswordWr",
    },
    {
        path: "/signup/confirm",
        component: ConfirmPasswordWr,
        title: "ConfirmPasswordWr",
    },
    {
        path: "/signup/terms",
        component: TermsAndPrivacyWr,
        title: "TermsAndPrivacyWr",
    },
    {
        path: "/signup/code",
        component: VerificationWr,
        title: "VerificationWr",
    },
    {
        path: "/signup/nickname",
        component: NickNameWr,
        title: "NickNameWr",
    },
    {
        path: "/forgot/email",
        component: ForgotPasswordEmailWr,
        title: "ForgotPasswordEmailWr",
    },
    {
        path: "/forgot/code",
        component: ForgotPasswordWr,
        title: "ForgotPasswordWr",
    },
    {
        path: "/forgot/pass",
        component: CreatePasswordWr,
        title: "CreatePasswordWr",
    },
    {
        path: "/forgot/confirm",
        component: ConfirmPasswordWr,
        title: "ConfirmPasswordWr",
    },
    {
        path: "/dashboard",
        component: Hoc(Dashboard),
        title: "Dashboard",
    },
    {
        path: "/registration",
        component: ViewWithIllustrationWr,
        title: "Registration",
    },
    {
        path: "/dashboard/:roomId",
        component: Hoc(Room),
        title: "Project",
    },
    {
        path: "/profile",
        component: Hoc(Profile),
        title: "Profile",
    },
    {
        path: "/spaces",
        component: Hoc(Spaces),
        title: "Spaces",
    },
    // {
    //     path: "/support",
    //     component: Hoc(Support),
    //     title: "Support"
    // },
    // {
    //     path: "/history",
    //     component: Hoc(History),
    //     title: "Winter Tale History",
    // },
    {
        path: "/expired-link",
        component: Expired,
        title: "Expired Link"
    },
    {
        path: "/guest/:roomId",
        component: Guest,
        title: "Guest"
    },
    {
        path: "/fastex",
        component: FastexComp,
        title: "FastexAuth"
    },
    {
        path: "/404",
        component: NotFound,
        title: "404",
    },
    {
        path: "/maintenance",
        component: Maintenance,
        title: "Maintenance"
    },
    {
        path: "/set_new_password/:token",
        component: ForgotPassword,
        title: "Forgot Password"
    },
    {
        path: "/room/:roomId",
        component: RedirectContent,
        title: "Partners Redirect"
    },
    {
        path: "/dream-package/:roomId",
        component: DreamPackage,
        title: "Dream Package Redirect"
    },
    {
        path: "/invite-user-redirect/:roomId",
        component: InviteContent,
        title: " Invite Content"
    }
];

export default routes;
