import {ReactComponent as Ru} from '../assets/icons/languages/RU.svg';
import {ReactComponent as De} from '../assets/icons/languages/DE.svg';
import {ReactComponent as Ua} from '../assets/icons/languages/UA.svg';
import {ReactComponent as Ko} from '../assets/icons/languages/KO.svg';
import {ReactComponent as Us} from '../assets/icons/languages/US.svg';
import {ReactComponent as Fr} from '../assets/icons/languages/FR.svg';
import {ReactComponent as Tr} from '../assets/icons/languages/TR.svg';
import {ReactComponent as Cn} from '../assets/icons/languages/CN.svg';

export const Languages = {
    ENG: {title: "ENG", icon: <Us />, value: "eng"},
    FR: {title: "FR", icon: <Fr />, value: "fr"},
    DE: {title: "DE", icon: <De />, value: "de"},
    KO: {title: "KO", icon: <Ko />, value: "ko"},
    RUS: {title: "RUS", icon: <Ru />, value: "ru"},
    CN: {title: "CN", icon: <Cn />, value: "zh-Hans"},
    TR: {title: "TR", icon: <Tr />, value: "tr"},
    UA: {title: "UA", icon: <Ua />, value: "uk"},
}