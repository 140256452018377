import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import routes from "./routes";
import {unityStore} from "./store/unityStore";
import {authStore} from "./store/authStore";
import DoubleLogin from "./components/Popups/DoubleLogin";
import Loading from "./components/Loading";
import InviteShowPopup from "./components/Popups/InviteShowPopup";
import ErrorModal from "./components/ModalError";
import RotateDevice from "./components/RotateDevice";
import Mobile from "./containers/mobile";
import ParticipantsPopup from "./components/Popups/ParticipantsPopup";
import TransitionRouter from "./components/TransitionRouter";
import useRotate from "./hooks/useRotate";
import useTranslates from "./hooks/useTranslates";

const UnityLayout = lazy(() => import("./containers/unityLayout"));

const App = observer(() => {
  const { loadUnity, isActiveMobile } = unityStore;
  const { doubleLogin, inviteMessage } = authStore;

  const showRotateDevice = useRotate(false);
  // useEffect(() => {
  //   navigator.getInstalledRelatedApps().then((data) => {
  //     console.log(data)
  //     console.log(navigator)
  //   })
  // }, [])

    useTranslates();

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <TransitionRouter>
          <Routes>
            {routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
                title={item.title}
                exact
              />
            ))}
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </TransitionRouter>
        <Suspense fallback={null}>
          {loadUnity && !isActiveMobile && <UnityLayout />}
          {isActiveMobile && <Mobile />}
        </Suspense>
        {doubleLogin && <DoubleLogin />}
        {!!inviteMessage && <InviteShowPopup />}
        <ParticipantsPopup />
      </BrowserRouter>
      {showRotateDevice && <RotateDevice />}
      <Loading />
      <ErrorModal />
    </Suspense>
  )
})

export default App;
