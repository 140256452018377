import React from "react";
import "./index.scss";

const RotateDevice = () => {
    return (
        <div className="rotate-container">
            <span className="rotate-img"/>
        </div>
    )
}

export default RotateDevice;